import moment from "moment";
export default {
  //to get base url of the app
  baseUrl(state) {
    let pathParts = location.pathname.split("/");
    let url = "";
    if (state.isProduction === 0) {
      url = location.origin + "/" + pathParts[1].trim("/") + "/"; // http://localhost/hrapponline/
    } else {
      url = location.origin + "/"; // http://subdomain.hrapp.co/
    }
    return url;
  },

  //to get orgCode dynamically from the current url
  orgCode() {
    let url = window.location.href;
    let urlNoProtocol = url.replace(/^https?:\/\//i, "");
    let orgCode = urlNoProtocol.split(".");
    orgCode = orgCode[0];
    return orgCode;
  },

  //to get domain dynamically from the current url
  domain(state) {
    if (state.isProduction === 0) {
      return "hrapp";
    } else {
      let url = window.location.href;
      let urlNoProtocol = url.replace(/^https?:\/\//i, "");
      let domain = urlNoProtocol.split(".");
      domain = domain[1];
      if (domain === undefined) {
        domain = "hrapp";
      }
      return domain;
    }
  },

  //to get accessRights for the particular form
  //if formName not sent then return all forms with rights
  formAccessRights: (state) => (formName) => {
    if (state.formAccessRights && formName) {
      let accessRights = JSON.parse(state.formAccessRights);
      accessRights = accessRights[`${formName}`]
        ? accessRights[`${formName}`]
        : ""; // check access rights exists for that form and return
      return accessRights;
    }
    // if form access rights from BE is not retrieved, we can return empty object
    return null;
  },

  formIdBasedAccessRights: (state) => (formId) => {
    if (state.formIdAccessRights && formId) {
      let accessRights = state.formIdAccessRights;
      accessRights = accessRights[`${formId}`] ? accessRights[`${formId}`] : ""; // check access rights exists for that form and return
      return accessRights;
    }
    // if form access rights from BE is not retrieved, we can return empty object
    return null;
  },

  // function to return all the emp-data-setup form access
  empDataSetupFormAccess: (state, getters) => {
    let formAccess = {},
      isAnyOneFormHaveAccess = false;
    for (let empDataSetupForm of state.employeeDataSetupForms) {
      var formRights = getters.formAccessRights(empDataSetupForm.formName);
      var dFormName = empDataSetupForm.displayFormName;
      dFormName =
        dFormName === "Projects" ? state.projectLabel + "s" : dFormName;
      if (formRights && formRights.accessRights["view"]) {
        formAccess[dFormName] = {
          ...empDataSetupForm,
          havingAccess: true,
        };
        isAnyOneFormHaveAccess = true;
      } else {
        formAccess[dFormName] = {
          ...empDataSetupForm,
          havingAccess: false,
        };
      }
    }
    return {
      formAccess,
      isAnyOneFormHaveAccess: isAnyOneFormHaveAccess,
    };
  },

  orgStructureFormAccess: (state, getters) => {
    let formAccess = {},
      isAnyOneFormHaveAccess = false;
    for (let empDataSetupForm of state.coreHrOrgStructureForms) {
      var formRights = getters.formAccessRights(empDataSetupForm.formName);
      var dFormName = empDataSetupForm.displayFormName;
      if (formRights && formRights.accessRights["view"]) {
        formAccess[dFormName] = {
          ...empDataSetupForm,
          havingAccess: true,
        };
        isAnyOneFormHaveAccess = true;
      } else {
        formAccess[dFormName] = {
          ...empDataSetupForm,
          havingAccess: false,
        };
      }
    }
    return {
      formAccess,
      isAnyOneFormHaveAccess: isAnyOneFormHaveAccess,
    };
  },

  coreHrSettingsFormAccess: (state, getters) => {
    let formAccess = {},
      isAnyOneFormHaveAccess = false;
    for (let corHrFrom of state.coreHrSettingsForms) {
      var formRights = corHrFrom.formId
        ? getters.formIdBasedAccessRights(corHrFrom.formId)
        : getters.formAccessRights(corHrFrom.formName);
      let accessType = corHrFrom.accessType ? corHrFrom.accessType : "view";
      if (formRights && formRights.accessRights[accessType]) {
        formAccess[corHrFrom.displayFormName] = {
          ...corHrFrom,
          havingAccess: true,
        };
        isAnyOneFormHaveAccess = true;
      } else {
        let subFormHaveAccess = false;
        if (corHrFrom.subForms && corHrFrom.subForms.length > 0) {
          for (let subForm of corHrFrom.subForms) {
            var subFormRights = getters.formAccessRights(subForm);
            if (subFormRights && subFormRights.accessRights["view"]) {
              formAccess[corHrFrom.displayFormName] = {
                ...corHrFrom,
                havingAccess: true,
              };
              isAnyOneFormHaveAccess = true;
              subFormHaveAccess = true;
              break;
            }
          }
        }
        if (!subFormHaveAccess) {
          formAccess[corHrFrom.displayFormName] = {
            ...corHrFrom,
            havingAccess: false,
          };
        }
      }
    }
    return {
      formAccess,
      isAnyOneFormHaveAccess: isAnyOneFormHaveAccess,
    };
  },

  checkAnyOneOfTheAdmin: (state, getters) => {
    let isAnyOneFormHaveAccess = false;
    for (let adminForm of state.adminForms) {
      let formName = adminForm.replace(/\s/g, "-");
      formName = formName.toLowerCase();
      var formRights = getters.formAccessRights(formName);
      if (formRights && formRights.accessRights["update"]) {
        isAnyOneFormHaveAccess = true;
        break;
      }
    }
    return isAnyOneFormHaveAccess;
  },

  //to get url for the particular form
  //if moduleName not sent then return all modules
  //filtering for particular form url will be done in that particular form
  redirectionFormUrl: (state) => (moduleName) => {
    if (state.sideBarMenuList && moduleName) {
      let sidebarMenu = state.sideBarMenuList;
      sidebarMenu = sidebarMenu.filter((el) => el.moduleName === moduleName);
      return sidebarMenu;
    } else {
      let sidebarMenu = state.sideBarMenuList;
      return sidebarMenu;
    }
  },
  //To get organization name of the employee
  organizationName(state) {
    const { organizationName } = state.orgDetails;
    if (organizationName) {
      return organizationName;
    } else {
      return "";
    }
  },

  // get snackbar store data
  getSnackbarData(state) {
    let snackbarData = {
      isOpen: state.showSnackbar,
      message: state.snackBarMessage,
      type: state.snackBarType,
      timeout: state.snackBarTimeout,
    };
    return snackbarData;
  },
  // get array pf assessment month and years(Ex: April 2020 to March 2021 )
  getAssessmentMonthYear: (state) => {
    const { assessmentYear } = state.orgDetails;
    let months = moment.monthsShort();
    let previousAssessmentYearMonths = [],
      currentAssessmentYearMonths = [];
    if (assessmentYear) {
      let prevAssessment = assessmentYear - 1;
      for (let i = 1; i <= months.length; i++) {
        // for months Jan to March we are using the current assessment year
        if (i <= 3) {
          currentAssessmentYearMonths.push(
            months[i - 1] + " " + assessmentYear
          );
        }
        // for April month we need to use both current and previous assessment year
        else if (i === 4) {
          currentAssessmentYearMonths.push(
            months[i - 1] + " " + assessmentYear
          );
          previousAssessmentYearMonths.push(
            months[i - 1] + " " + prevAssessment
          );
        }
        // other than above months, we need to use previous assessment year
        else {
          previousAssessmentYearMonths.push(
            months[i - 1] + " " + prevAssessment
          );
        }
      }
    }
    let monthWithAssessmentYear = previousAssessmentYearMonths.concat(
      currentAssessmentYearMonths
    );
    return monthWithAssessmentYear;
  },

  //check whether form is in vue 2 layout
  checkForVue2LayoutForms: (state) => (formName) => {
    const { vue2Forms } = state;
    if (vue2Forms.length > 0) {
      let formsInVueLayout = vue2Forms.filter((form) => form === formName);
      return formsInVueLayout.length > 0;
    } else {
      return false;
    }
  },

  //check whether form is in vue 3 layout
  checkForVue3LayoutForms: (state) => (formName) => {
    const { vue3Forms } = state;
    if (vue3Forms.length > 0) {
      let formsInVueLayout = vue3Forms.filter((form) => form === formName);
      return formsInVueLayout.length > 0;
    } else {
      return false;
    }
  },

  checkForPartnerLayoutForms: (state) => (formName) => {
    const { partnerForms } = state;
    if (partnerForms.length > 0) {
      let formsInPartnerLayout = partnerForms.filter(
        (form) => form === formName
      );
      return formsInPartnerLayout.length > 0;
    } else {
      return false;
    }
  },

  //to give the table height
  getTableHeight: () => (removeHeight) => {
    let systemBarActiveHeight = document.getElementById("systembar") ? 60 : 0;
    let calculatedHeight = systemBarActiveHeight + removeHeight;
    return `calc(100vh - ${calculatedHeight}px)`;
  },

  lossPreventionFormAccess: (state, getters) => {
    let formAccess = {},
      isAnyOneFormHaveAccess = false;
    for (let dataLossForm of state.dataLossPreventionForm) {
      var formRights = getters.formAccessRights(dataLossForm.formName);
      var dFormName = dataLossForm.displayFormName;
      if (formRights && formRights.accessRights["view"]) {
        formAccess[dFormName] = {
          ...dataLossForm,
          havingAccess: true,
        };
        isAnyOneFormHaveAccess = true;
      } else {
        formAccess[dFormName] = {
          ...dataLossForm,
          havingAccess: false,
        };
      }
    }
    return {
      formAccess,
      isAnyOneFormHaveAccess: isAnyOneFormHaveAccess,
    };
  },
};
