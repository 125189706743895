import firebase from "firebase/app";
import "firebase/auth";
import Vue from "vue";
import config from "../config";
import moment from "moment";
import { createProvider } from "../apolloConfiguration";
import {
  ADD_ERROR_LOG,
  CHECK_ACCESS_RIGHTS,
  LIST_CUSTOM_GROUPS,
  GET_PRESIGNED_URL,
  GET_EMPLOYEE_LIST,
  DELETE_PRESIGNED_URL,
  GET_ALL_EMPLOYEES,
} from "@/graphql/commonQueries";
import { ADD_CUSTOM_GROUP } from "@/graphql/core-hr/customGroupQueries.js";
import {
  GET_ORGANIZATION_SUBSCRIPTION_DETAILS,
  GET_STATES,
} from "@/graphql/billing/billingQueries";
import {
  getErrorCodesWithValidationFromMessage,
  getErrorCodes,
  handleNetworkErrors,
} from "@/helper";
import {
  GET_SUBSCRIBED_PLAN_TYPE,
  ENCRYPT_REFRESH_TOKEN,
  LOGOUT_ENTOMO,
} from "@/graphql/layout/layoutQueries.js";

export default {
  //*Get user Ip address
  async fetchUserIp({ commit }) {
    var userIpAddress = "";
    await Vue.prototype.$http
      .get(config.ipAddressApi)
      .then((res) => {
        userIpAddress = res.data.ip;
        window.$cookies.set("userIpAddress", userIpAddress);
      })
      .catch(() => {
        userIpAddress = "";
      });
    commit("UPDATE_USER_IP_ADDRESS", userIpAddress);
  },

  //clear form lock if it set for any of the form user worked
  clearUserLock({ dispatch, getters }) {
    let baseUrl = getters.baseUrl;
    Vue.prototype.$http
      .get(baseUrl + "auth/index/clear-session-throw-lock")
      .then((clearLockResponse) => {
        if (clearLockResponse.data && clearLockResponse.data.success) {
          dispatch("userLogout");
        } else {
          dispatch("userLogout");
        }
      })
      .catch(() => {
        dispatch("userLogout");
      });
  },

  //*Logout Current User from firebase
  userLogout({ dispatch }) {
    let partnerid = window.$cookies.get("partnerid");
    if (partnerid && partnerid.toLowerCase() === "entomo") {
      const {
        clients: { apolloClientH },
      } = createProvider();
      return new Promise(() => {
        apolloClientH
          .mutate({
            mutation: LOGOUT_ENTOMO,
            variables: {
              xAuthToken: window.$cookies.get("accessToken"),
            },
          })
          .then((response) => {
            if (response.data.logoutFromEntomo) {
              dispatch("clearUserSession");
            } else {
              dispatch("clearUserSession");
            }
          })
          .catch(() => {
            dispatch("clearUserSession");
          });
      });
    } else {
      const firebaseCurrentUser = firebase.auth().currentUser;
      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            dispatch("clearUserSession");
          })
          .catch(() => {
            dispatch("clearUserSession");
          });
      } else {
        dispatch("clearUserSession");
      }
    }
  },

  // restore store to default value on logout
  clearUserSession({ commit, getters }) {
    commit("RESTORE_DEFAULT_STATE");
    localStorage.clear();
    window.$cookies.remove("empUid");
    window.$cookies.remove("accessToken");
    window.$cookies.remove("refreshToken");
    window.$cookies.remove("userIpAddress");
    location.replace(getters.baseUrl + "auth");
  },

  // action to retrieve tax amount based on new and old tax regime
  retrieveTaxRegimeAmount({ dispatch, commit, getters }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(getters.baseUrl + "payroll/tax-rules/compare-tax-regime", {
          requestResource: "HRAPPUI",
        })
        .then((result) => {
          let regimeResonseData = result.data;
          if (regimeResonseData && regimeResonseData.success) {
            var regimeDetails = regimeResonseData.regimeComparisionDetails;
            commit("UPDATE_TAX_COMPARISON_DETAILS", regimeDetails);
            //check whether want to show compare tax regime alert or not
            if (regimeDetails.Trigger_Regime_Change) {
              commit("SHOW_TAX_REGIME_SELECTION_OPTION", true);
            } else {
              commit("SHOW_TAX_REGIME_SELECTION_OPTION", false);
            }
          } else {
            //on error we hide the notify alert bar
            if (regimeResonseData.msg === "Session Expired") {
              dispatch("clearUserLock");
            } else {
              commit("SHOW_TAX_REGIME_SELECTION_OPTION", false);
            }
          }
          resolve(regimeDetails);
        })
        .catch((retrieveTaxRegimeError) => {
          //on error we hide the notify alert bar
          commit("SHOW_TAX_REGIME_SELECTION_OPTION", false);
          //on session expire error we clear session and redirect to auth
          if (retrieveTaxRegimeError.status === 200) {
            dispatch("clearUserLock");
          }
          reject(retrieveTaxRegimeError);
        });
    });
  },

  //send the ui error log to the server
  async addErrorLog(state, error) {
    let url = window.location.href;
    const {
      clients: { apolloClientR },
    } = createProvider();
    apolloClientR.mutate({
      mutation: ADD_ERROR_LOG,
      variables: {
        errorMessage: error,
        url: url,
      },
    });
  },

  // fetch access rights for passed form
  fetchFormAccessRights(root, formName) {
    const { state } = root;
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .query({
          query: CHECK_ACCESS_RIGHTS,
          variables: {
            formName: formName,
            employeeId: parseInt(state.orgDetails.employeeId),
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { errorCode, rights } = response.data.getAccessRights;
          if (!errorCode && rights) {
            resolve(rights);
          } else {
            reject("error");
          }
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  // get organization subscription details
  getOrganizationSubscriptionDetails({ commit }) {
    const {
      clients: { apolloClientG },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientG
        .query({
          query: GET_ORGANIZATION_SUBSCRIPTION_DETAILS,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data) {
            let {
              getOrganizationBillingDetails: {
                errorCode,
                autoBilling,
                manualBillingDetails,
                autoBillingDetails,
              },
            } = response.data; //destructuring data
            if (!errorCode) {
              commit("UPDATE_AUTO_BILLING_FLAG", autoBilling);
              let orgBillingDetails = {
                isAutoBilling: autoBilling,
                currentStatus: "",
                demoCompleted: true,
                showPaymentAlert: false,
                isPaymentDueExceed: false,
                orgDemoPaymentDetails: {},
              };
              if (manualBillingDetails) {
                let manualBillingResponse = JSON.parse(manualBillingDetails);
                orgBillingDetails["orgDemoPaymentDetails"] =
                  manualBillingResponse;
                // if the status is present-demo-booking-card -> demo booking card present, with empty layout
                if (
                  manualBillingResponse.subscriptionAlertStatus ===
                  "present-demo-booking-card"
                ) {
                  orgBillingDetails["demoCompleted"] = false;
                }
                // if the status is present-red-payment-alert -> present red alert, with empty layout without any access.
                else if (
                  manualBillingResponse.subscriptionAlertStatus ===
                  "present-red-payment-alert"
                ) {
                  orgBillingDetails["isPaymentDueExceed"] = true;
                }
                // if the status is present-orange-payment-alert - > present alert, with accessing of the application
                else if (
                  manualBillingResponse.subscriptionAlertStatus ===
                  "present-orange-payment-alert"
                ) {
                  orgBillingDetails["showPaymentAlert"] = true;
                }
                // if autoBilling is enabled, then we can use autoBillingDetails
                if (
                  autoBilling &&
                  autoBillingDetails &&
                  !orgBillingDetails["isPaymentDueExceed"] &&
                  orgBillingDetails["demoCompleted"]
                ) {
                  let autoBillingResponse = JSON.parse(autoBillingDetails);
                  const {
                    currentStatus,
                    trialRemainingDays,
                    subscriptionStatus,
                    subscriptionId,
                    subscribedPlanQuantity,
                    subscriptionCurrency,
                    subscriptionBillingPeriod,
                    portalPlanId,
                  } = autoBillingResponse;
                  commit(
                    "UPDATE_AUTO_BILLING_PLAN_CURRENT_STATUS",
                    currentStatus
                  );
                  commit("UPDATE_SUBSCRIPTION_PLAN_STATUS", subscriptionStatus);
                  commit("UPDATE_AUTO_BILLING_DETAILS", {
                    planId: portalPlanId,
                    subscriptionId: subscriptionId,
                    subscribedPlanQuantity: subscribedPlanQuantity,
                    subscriptionCurrency: subscriptionCurrency,
                    subscriptionBillingPeriod: subscriptionBillingPeriod,
                  });
                  commit("UPDATE_TRIAL_PERIOD", trialRemainingDays);
                  orgBillingDetails["currentStatus"] = currentStatus;
                  resolve(orgBillingDetails);
                } else {
                  resolve(orgBillingDetails);
                }
              } else {
                reject();
              }
            } else {
              reject();
            }
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  },

  // get the list of whole countries
  listCountries({ commit }) {
    return new Promise((resolve) => {
      Vue.prototype.$http
        .get("https://restcountries.com/v3/all")
        .then((res) => {
          commit("UPDATE_COUNTRIES", res ? res.data : []);
          resolve(res ? res.data : []);
        })
        .catch(() => {
          commit("UPDATE_COUNTRIES", []);
          resolve([]);
        });
    });
  },

  // list states based on country code
  listStatesBasedOnCountry(state, payload) {
    const {
      clients: { apolloClientC },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientC
        .query({
          query: GET_STATES,
          variables: {
            countryCode: payload.countryCode,
          },
        })
        .then((response) => {
          const { errorCode, listStateDetails } = response.data.listStates;
          if (!errorCode && listStateDetails) {
            resolve(listStateDetails);
          } else {
            reject("error");
          }
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  // list custom group based on form name
  listCustomGroupBasedOnFormName(state, payload) {
    const {
      clients: { apolloClientC },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientC
        .query({
          query: LIST_CUSTOM_GROUPS,
          variables: {
            formName: payload.formName,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { errorCode, customGroups } =
            response.data.listCustomEmployeeGroups;
          if (!errorCode) {
            resolve(customGroups ? customGroups : []);
          } else {
            reject("error");
          }
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  // clear lock to the particular record
  createCustomGroup({ commit, state }, { groupName, forms, notes, jsonQuery }) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .mutate({
          mutation: ADD_CUSTOM_GROUP,
          variables: {
            groupName: groupName,
            forms: forms,
            notes: notes,
            jsonQuery: jsonQuery,
            employeeId: parseInt(state.orgDetails.employeeId),
          },
        })
        .then((response) => {
          const { errorCode } = response.data.createCustomGroup;
          let snackbarData = {
            isOpen: true,
            message: "",
            type: "warning",
          };
          if (!errorCode) {
            snackbarData.type = "success";
            snackbarData.message = "Custom group created successfully.";
            commit("OPEN_SNACKBAR", snackbarData);
            resolve("success");
          } else {
            snackbarData.message =
              "Something went wrong while creating the custom group. Please try after some time or talk to your platform administrator.";
            commit("OPEN_SNACKBAR", snackbarData);
            reject("");
          }
        })
        .catch((error) => {
          let commonErrMsg =
            "Something went wrong while creating the custom group. If you continue to see this issue, please contact the platform administrator.";
          let snackbarData = {
            isOpen: true,
            message: "",
            type: "warning",
          };
          if (error && error.graphQLErrors) {
            let errorCode = getErrorCodesWithValidationFromMessage(error);
            if (errorCode) {
              switch (errorCode[0]) {
                case "_DB0000": //- Technical issue
                  snackbarData.message =
                    "It's us!, There seems to be some issue. Would you mind trying it after some time or talk to your administrator?";
                  break;
                case "BAD_USER_INPUT": // if any input validation error occurs, BAD_USER_INPUT was returned as error code from backend
                case "IVE0000": // invalid input request
                  var validationErrors = errorCode[1];
                  // add all the backend validation error messages as single sentence to present it to the users
                  var validationMessages = "";
                  if (validationErrors) {
                    for (var errCode in validationErrors) {
                      // Please provide a valid group name.
                      if (errCode === "IVE0006") {
                        validationMessages = validationErrors[errCode];
                      }
                    }
                  }
                  // checking the validation message availability
                  if (validationMessages) {
                    snackbarData.message = validationMessages;
                  }
                  // other validation errors are not handled by users. So as of now it was considers as common error.
                  else {
                    snackbarData.message = commonErrMsg;
                  }
                  break;
                case "_DB0101": // This employee do not have create access rights
                  snackbarData.message =
                    "Sorry, you don't have access to create custom group. Please contact HR administrator.";
                  break;
                case "_DB0001": // Error while retrieving the employee access rights
                case "_DB0002": // Error while trying to check the employee access rights
                case "_DB0104": // While check access rights form not found'
                case "_UH0001": // unhandled error from backend
                case "ERE0016": // Error while creating the custom employee groups.
                case "ERE0116": // Error while process the request for creating the custom employee groups.
                default:
                  snackbarData.message = commonErrMsg;
                  break;
              }
            } else {
              snackbarData.message = commonErrMsg;
            }
          } else if (error && error.networkError) {
            snackbarData.message = handleNetworkErrors(error);
          }
          commit("OPEN_SNACKBAR", snackbarData);
          reject("");
        });
    });
  },

  getPlanType({ dispatch }) {
    const {
      clients: { apolloClientC },
    } = createProvider();
    return new Promise((resolve) => {
      apolloClientC
        .query({
          query: GET_SUBSCRIBED_PLAN_TYPE,
        })
        .then((response) => {
          const {
            getOrganizationSubscribedPlan: { errorCode, subscribedDashboard },
          } = response.data;
          if (!errorCode && subscribedDashboard) {
            let planType = dispatch("setPlanType", subscribedDashboard);
            resolve(planType);
          } else {
            resolve(dispatch("setPlanType"));
          }
        })
        .catch(() => {
          resolve(dispatch("setPlanType"));
        });
    });
  },

  setPlanType(state, planType = "") {
    let dashboardType = "";
    // plan type is retrieved from BE, then we can use this and also set the value in cookie
    if (planType) {
      dashboardType = planType;
      // set expiry time as 17 days from current date
      let planTypeExpiryTime = moment().add(17, "days");
      window.$cookies.set(
        "orgSubscribedPlan",
        dashboardType,
        planTypeExpiryTime.toDate().toUTCString()
      );
    }
    // when plan is not retrieved from BE(any case of error in endpoint)
    else {
      // get the plan type from cookie
      let planTypeInCookie = window.$cookies.get("orgSubscribedPlan");
      /**
       * if the plan type is already exist in cookie, then we can get that value when we get error/empty response.
       * Otherwise we can set hrms plan type as default
       */
      dashboardType = planTypeInCookie ? planTypeInCookie : "HRMSDASHBOARD";
    }
    return dashboardType;
  },

  // encrypt RefreshToken and redirect to partner page
  encryptRefreshToken({ commit }, { formURL }) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .query({
          query: ENCRYPT_REFRESH_TOKEN,
          fetchPolicy: "no-cache",
          variables: {
            refreshToken: window.$cookies.get("refreshToken"),
          },
        })
        .then((response) => {
          const { errorCode, refreshToken } = response.data.encryptRefreshToken;
          if (!errorCode) {
            window.location.href =
              "https://" + formURL + "/?rtkn=" + refreshToken;
            resolve("");
          } else {
            let snackbarData = {
              isOpen: true,
              message:
                "Something went wrong while redirecting. Please try after some time.",
              type: "warning",
            };
            commit("OPEN_SNACKBAR", snackbarData);
            reject("error");
          }
        })
        .catch(() => {
          let snackbarData = {
            isOpen: true,
            message:
              "Something went wrong while redirecting. Please try after some time.",
            type: "warning",
          };
          commit("OPEN_SNACKBAR", snackbarData);
          reject("error");
        });
    });
  },

  // get address from google api with latitude and longitude
  getAddressFromLatLng(state, coordinates) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${config.googleMapsAPIKey}`;
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .get(url)
        .then((response) => {
          if (coordinates.addressType === "city") {
            resolve(response.data.results[6].formatted_address);
          } else {
            resolve(response.data.results[0].formatted_address);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // list custom group based on form name
  s3FileUploadRetrieveAction(state, payload) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .mutate({
          mutation: GET_PRESIGNED_URL,
          variables: payload,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data.getPresignedUrl) {
            resolve(response.data.getPresignedUrl);
          } else {
            reject("error");
          }
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  //deleting s3 file

  deletes3File(state, payload) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .mutate({
          mutation: DELETE_PRESIGNED_URL,
          variables: payload,
        })
        .then((response) => {
          if (response.data.deleteS3Files) {
            resolve(response.data.deleteS3Files);
          } else {
            reject("error");
          }
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  // list custom group based on form name
  getEmployeesList(state, payload) {
    const {
      clients: { apolloClientI },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientI
        .query({
          query: GET_EMPLOYEE_LIST,
          variables: {
            formName: payload.formName,
          },
        })
        .then((response) => {
          const { errorCode, employeeDetails } =
            response.data.getEmployeesDetailsBasedOnRole;
          if (!errorCode) {
            resolve(employeeDetails);
          } else {
            reject("error");
          }
        })
        .catch(() => {
          reject("error");
        });
    });
  },

  getAllEmployeesList(state, { formName, isActiveOnly = 1 }) {
    const {
      clients: { apolloClientC },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientC
        .query({
          query: GET_ALL_EMPLOYEES,
          variables: {
            designationId: [],
            departmentId: [],
            empTypeId: [],
            workScheduleId: [],
            locationId: [],
            formName: formName,
            onlyActiveEmployees: isActiveOnly,
          },
        })
        .then((response) => {
          const { errorCode, employeeList } =
            response.data.listAllEmployeeDetails;
          if (!errorCode && employeeList) {
            resolve(employeeList);
          } else {
            reject("error");
          }
        })
        .catch((fetchError) => {
          if (fetchError && fetchError.graphQLErrors) {
            let errorCode = getErrorCodes(fetchError);
            if (errorCode) {
              switch (errorCode) {
                case "_DB0000": //- Technical error
                  reject(
                    "It’s us! There seems to be some technical difficulties while fetching the employees. Please try after some time."
                  );
                  break;
                case "DB0100": // This employee do not have view access rights
                  reject(
                    "Sorry, you don't have access rights to view the employees. Please contact HR administrator."
                  );
                  break;
                case "EM0007": // Error while listing the employees.
                case "_DB0002": // Error while checking the employee access rights
                case "_DB0104": // While check access rights form not found.
                default:
                  reject("error");
                  break;
              }
            } else {
              reject("error");
            }
          } else if (fetchError && fetchError.networkError) {
            reject(handleNetworkErrors(fetchError));
          } else {
            reject("error");
          }
        });
    });
  },
};
