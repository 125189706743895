import moment from "moment";
import store from "./store";
// convert the date time in universal format for supporting all browsers
export function formatDateAndTime(dateTime) {
  let date = dateTime,
    values = date.split(/[^0-9]/),
    year = parseInt(values[0], 10),
    month = parseInt(values[1], 10) - 1, // Month is zero based, so subtract 1
    day = parseInt(values[2], 10),
    hours = parseInt(values[3], 10),
    minutes = parseInt(values[4], 10),
    seconds = parseInt(values[5], 10),
    formattedDate;

  formattedDate = new Date(year, month, day, hours, minutes, seconds);
  return formattedDate;
}

//function to convert date to organization date formate
export function orgDateFormatter(date, withTime = false) {
  const { orgDateFormat } = store.state.orgDetails;
  if (date) {
    const day = moment(date);
    let format = orgDateFormat ? orgDateFormat : "YYYY/MM/DD";
    if (withTime) {
      format = format + " HH:mm:ss";
    }
    return day.format(format);
  } else {
    return "";
  }
}
//function to convert date to backend format to send in Api calls
export function backendDateFormatter(date) {
  if (date) {
    const day = moment(date);
    let format = "YYYY/MM/DD";
    return day.format(format);
  } else {
    return "";
  }
}

//sorting array of objects
export function sortingArrayData(list, fieldName) {
  let templateList = list;
  if (templateList.length > 0) {
    templateList.sort((a, b) =>
      a[`${fieldName}`] > b[`${fieldName}`] ? 1 : -1
    );
    return templateList;
  } else {
    return [];
  }
}

//filter a value in array of objects takes param as list,search keyword, array items on which need to search element
export function searchList(list, searchQuery, searchColumn) {
  if (searchQuery) {
    let searchTerm = searchQuery.toLowerCase();
    let listData = [...list];
    return listData.filter((item) => {
      return searchColumn.some((field) => {
        return item[`${field}`].toString().toLowerCase().includes(searchTerm);
      });
    });
  } else {
    return list;
  }
}

//filter a value in array of objects takes param as list,search keyword, item on which need to filter element
export function filterList(list, filterValue, filterColumn) {
  if (filterValue && filterValue !== "All") {
    let listData = [...list];
    return listData.filter((item) => {
      return item[`${filterColumn}`] === filterValue;
    });
  } else {
    return list;
  }
}

/**
 * This function is ued to check the value exist in Array or Array of object.
 * based on objectKey we decide the list as array or array of object
 * We also can add Object in future when it needs
 **/
export function checkValExistsInList(list, searchValue, objectKey = null) {
  // if key is passed, then the list must be an array of object
  if (objectKey) {
    return list.some((el) =>
      el[`${objectKey}`]
        ? searchValue.toString().toLowerCase() ===
          el[`${objectKey}`].toString().toLowerCase()
        : false
    );
  } else {
    // list is array
    return list.some(
      (el) =>
        searchValue.toString().toLowerCase() === el.toString().toLowerCase()
    );
  }
}
// Parse the errorCodes that is thrown from BE using Apollo Server
export function getErrorCodes(errorList) {
  for (let err of errorList.graphQLErrors) {
    return err.extensions.code;
  }
}

//  Parse the errorCodes along with validation errorCodes that is thrown from BE using Apollo Server
export function getErrorCodesWithValidation(errors) {
  for (let err of errors.graphQLErrors) {
    return [
      err.extensions.code,
      err.extensions.validationError
        ? err.extensions.validationError // return whole validation json to use backend validation messages in ui presentation
        : null,
      err.message,
    ];
  }
}

//  Parse the errorCodes along with validation errorCodes that is thrown from BE using Apollo Server
export function getErrorCodesAndMessagesWithValidation(errors) {
  for (let err of errors.graphQLErrors) {
    return [
      err.extensions.code,
      err.message,
      err.extensions.validationError
        ? err.extensions.validationError // return whole validation json to use backend validation messages in ui presentation
        : null,
    ];
  }
}

//  Parse the errorCodes along with validation errorCodes that is thrown from BE using Apollo Server from error message
export function getErrorCodesWithValidationFromMessage(errors) {
  for (let err of errors.graphQLErrors) {
    if (err.message) {
      let errorMessage = JSON.parse(err.message);
      return [
        errorMessage.errorCode,
        errorMessage.validationError
          ? errorMessage.validationError // return whole validation json to use backend validation messages in ui presentation
          : null,
      ];
    } else {
      return null;
    }
  }
}

//remove an item from array and return if exist otherwise return input array
export function removeItemFromArray(arrayList, item, replaceItem = "") {
  let itemIndex = arrayList.indexOf(item);
  if (itemIndex === -1) {
    return arrayList;
  } else {
    replaceItem
      ? arrayList.splice(itemIndex, 1, replaceItem)
      : arrayList.splice(itemIndex, 1); // if there is any replace item, then the particular index has to be replaced with that item
    return arrayList;
  }
}

// remove item from array of object
export function removeItemFromArrayOfObject(arrayObjList, value, attr) {
  var i = arrayObjList.length;
  while (i--) {
    if (
      arrayObjList[`${i}`] && // check array of object exist
      arguments.length > 2 && // check passed arguments length greater than 2
      arrayObjList[`${i}`][`${attr}`] === value // check the attr(array of object key) with passed value
    ) {
      // if all these conditions match, then remove that item from array of object
      arrayObjList.splice(i, 1);
    }
  }
  return arrayObjList;
}
// find last day of the month by passing year and month (2020, (jan represents 1, feb -> 2 and so on))
export function lastDateOfMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

//function to calculate the monthly amount of salary components in template
//based on period it will calculate and rounded off before sending it to the form
export function calculateMonthlyYearlyValue(value, percent = "", period = "") {
  if (value === 0) {
    return { monthly: 0, annual: 0 };
  } else {
    let amount = percent ? getPercentOfValue(value, percent) : value;
    let monthlyValue, annualValue;
    switch (period) {
      case "Monthly":
        monthlyValue = amount;
        break;
      case "Quarterly":
        monthlyValue = amount / 3;
        break;
      case "HalfYearly":
        monthlyValue = amount / 6;
        break;
      case "Annually":
        monthlyValue = amount / 12;
        break;
      default:
        monthlyValue = amount;
        break;
    }
    monthlyValue = store.getters["payroll/roundOffValue"]({
      type: "Salary",
      value: monthlyValue,
    });
    annualValue = monthlyValue * 12;
    return { monthly: monthlyValue, annual: annualValue };
  }
}

//function to calculate the annual amount of salary components in template
//based on period it will calculate and rounded off before sending it to the form
export function calculateAnnualValue(value) {
  if (value === 0) {
    return 0;
  } else {
    let annualValue = value * 12;
    annualValue = store.getters["payroll/roundOffValue"]({
      type: "Salary",
      value: annualValue,
    });
    return annualValue;
  }
}
//function to calculate some percent from the original value
//param1-value from which need to find percent
//param2-how many percent need to calculate from the amount
//param3-to round off final value
export function getPercentOfValue(value, percent, roundOffType = "") {
  let calculateValue = (value * percent) / 100;
  if (roundOffType) {
    calculateValue = store.getters["payroll/roundOffValue"]({
      type: roundOffType,
      value: calculateValue,
    });
  }
  return calculateValue;
}
//function to fixed off values based on digits passed and with 2 digit after point by default.
export function fixedOffValues(value, digits = 2) {
  if (value) {
    let decimalValue = value.toFixed(digits);
    //fixed of normally act on whole number too(eg:8 -> 8.00) to avoid that replace 00's with empty(eg:8.00 ->8)
    return decimalValue.replace(/\.00$/, "");
  } else {
    return 0;
  }
}

//function to change particular object property in all record of an array(array of objects)
export function setAllPropertyOfObjectInArray(list, objectProperty) {
  let updatedList = list.map((el) => {
    return Object.assign({}, el, objectProperty);
  });
  return updatedList;
}

//function to replace particular items of an specific record in an array of objects
export function findAndSetProperty(
  list,
  objectProperty,
  findColumn,
  findValue
) {
  let updatedList = list.map((obj) => {
    if (obj[`${findColumn}`] === findValue) {
      return {
        ...obj,
        ...objectProperty,
      };
    }
    return obj;
  });
  return updatedList;
}
//function to compare two array of objects and return array with only modified records
export function compareAndReturnModifiedArray(
  originalList,
  modifiedList,
  findColumn = []
) {
  let columns;
  if (findColumn.length > 0) {
    columns = findColumn;
  } else {
    columns = Object.keys(originalList[0]).map((key) => key);
  }

  let modifiedArrayList = modifiedList.filter((item, index) => {
    return columns.some((field) => {
      if (
        modifiedList[`${index}`][`${field}`] !==
        originalList[`${index}`][`${field}`]
      ) {
        return true;
      }
    });
  });
  return modifiedArrayList;
}

// compare two json objects and return the diff data
export function compareAndReturnModifiedObject(originalObject, modifiedObject) {
  const a = originalObject;
  const b = modifiedObject;

  const diffObject = (a, b) =>
    Object.fromEntries(
      Object.entries(b).filter(([key, val]) => key in a && a[`${key}`] !== val)
    );
  return diffObject(a, b);
}

// remove newline and multiple spaces in a sentance
export function replaceSentanceWithoutExtraSapceAndNewline(sentance) {
  if (sentance) {
    let modifiedSentance = sentance.replace(/(\r\n|\n|\r)/gm, "");
    modifiedSentance.replace(/\s\s+/g, " ");
    return modifiedSentance;
  }
  return "";
}

// sanitize input values which is passed to this function
export function sanitizeInputs(input) {
  if (input) {
    let inputVal = input.toString();
    // replace all this special characters ',",*,\,[,],{,} to empty
    return inputVal.replace(/[\[\]\\{}*'"]+/g, "");
  }
  return "";
}

// function to handle null values in presentation
export function checkNullValue(value) {
  if (!value) return "-";
  return value;
}

// copy text from clipboard
export function copyTextFromClipboard(fieldId) {
  /* Get the text field */
  var copyText = document.getElementById(fieldId);
  if (copyText) {
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  }
}

// convert passed time value into seconds based on time format
export function changeTimeToSeconds(time, format = "hh:mm") {
  return moment(time, format).diff(moment().startOf("day"), "seconds");
}

// change passed seconds value into hh:mm:ss or hh:mm format
export function changeSecondsToHoursFormat(totalSeconds, format = "hh:mm") {
  // get hours from totalSeconds
  let hours = parseInt(totalSeconds / (60 * 60));
  // get remaining seconds from totalSeconds
  let reminingSeconds = totalSeconds % (60 * 60);
  // get minutes from reminingSeconds
  let minutes = parseInt(reminingSeconds / 60);
  // get seconds from reminingSeconds
  let seconds = reminingSeconds % 60;
  let formattedHours =
      parseInt(hours) > 9 ? parseInt(hours) : "0" + parseInt(hours),
    formattedMinutes =
      parseInt(minutes) > 9 ? parseInt(minutes) : "0" + parseInt(minutes),
    formattedSeconds = seconds > 9 ? seconds : "0" + seconds;
  // result time example: 09:40:59/09:40
  return format === "hh:mm:ss"
    ? formattedHours + ":" + formattedMinutes + ":" + formattedSeconds
    : formattedHours + ":" + formattedMinutes;
}

// check the time is between two times
export function checkTimeBetweenTwoTimes(
  time,
  beforeTime,
  afterTime,
  isSameTime = false,
  format = "hh:mm"
) {
  let formattedTime = moment(time, format),
    formattedBeforeTime = moment(beforeTime, format),
    formattedAfterTime = moment(afterTime, format);
  if (formattedTime.isBetween(formattedBeforeTime, formattedAfterTime)) {
    return true;
  } else if (isSameTime) {
    if (formattedTime.isSame(formattedBeforeTime, formattedAfterTime)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

// check the passed date & time is between two dates
export function checkTimeBetweenTwoDates(
  date,
  startDate,
  endDate,
  isSameTime = false
) {
  let formattedDate = moment(date),
    formattedBeforeDate = moment(startDate),
    formattedAfterDate = moment(endDate);
  if (formattedDate.isBetween(formattedBeforeDate, formattedAfterDate)) {
    return true;
  } else if (isSameTime) {
    if (formattedDate.isSame(formattedBeforeDate, formattedAfterDate)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

// convert base64 to file object
export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
}

export function convertUTCToLocal(utcTime) {
  let format = store.state.orgDetails.orgDateFormat;
  const stillUTC = moment.utc(utcTime).toDate();
  return moment(stillUTC, "YYYY-MM-DD HH:mm:ss")
    .local()
    .format(format + " HH:mm:ss");
}

export function decimalToHoursAndMinutes(value) {
  let decimalValue = parseFloat(value);
  // Separate the whole and decimal parts of the number
  var hours = Math.floor(decimalValue);
  var minutes = Math.round((decimalValue - hours) * 60);
  return {
    hours: hours,
    minutes: (minutes < 10 ? "0" : "") + minutes,
  };
}

export function hoursAndMinutesToDecimal(hours, minutes) {
  // Calculate the decimal value
  var decimalValue = parseInt(hours) + parseInt(minutes) / 60;

  return parseFloat(decimalValue).toFixed(2);
}

export function handleNetworkErrors(error) {
  let sCode = error.networkError.statusCode;
  let eCode = sCode.toString();
  let errorMessage = "";
  if (eCode === "403" || eCode === "404") {
    errorMessage = error.message;
  } else if (eCode === "502" || eCode === "504") {
    errorMessage =
      "Sorry, there was a problem processing your request due to a temporary issue. If the problem persists, feel free to contact our support team for assistance" +
      " - " +
      eCode;
  } else {
    errorMessage =
      "Sorry, there was a problem processing your request. Please contact the platform administrator" +
      " - " +
      eCode;
  }
  return errorMessage;
}

export function convertSecToMins(value, format) {
  if (format === "hh:mm:ss") {
    if (!value) return "00:00:00";
    var sec_num = value;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  } else if (format === "mm:ss") {
    if (!value) return "00:00";
    var mins = Math.floor(value / 60);
    var sec = value - mins * 60;
    return mins + ":" + sec;
  } else {
    if (!value) return "0";
    var min = Math.floor(value / 60);
    return Math.round(min);
  }
}

export function getCustomFieldName(fieldId, oldFieldName) {
  let customFormFields = store.state.customFormFields;
  if (customFormFields && Object.keys(customFormFields).length > 0) {
    if (customFormFields[fieldId]) {
      return customFormFields[fieldId].Field_Alias;
    }
  }
  return oldFieldName;
}
